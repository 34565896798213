.logo {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.clearTrash{
    font-size:12pt;
}
.MuiDropzoneArea-root {
  min-height: auto !important;
  padding: 15px 0;

  .MuiDropzoneArea-text {
    margin-top: 0;
  }

  .MuiGrid-container {
    width: 100%;
    margin: 0;

    .MuiDropzonePreviewList-imageContainer {
      width: 100%;
      padding-bottom: 15px;
      max-width: 100%;
      flex-basis: 100%;

      .MuiDropzonePreviewList-image {
        width: 100%;
        height: 22.5rem;
        object-fit: cover;
      }

      .MuiDropzonePreviewList-removeButton {
        top: 10px;
        right: 15px;
      }
    }
  }
}
.galary_image {
  display: inline-block;
  float: left;
  margin-right: 0.5rem;
  position: relative;
}
.close_x {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
}
.file_item {
  position: relative;

  .file_name {
      padding-left: 5px;
      font-size: 0.6rem;
      white-space: nowrap;
      overflow: hidden;
      width: 5rem;
      text-overflow: ellipsis;
  }

  .close_x {
      position: absolute;
      top: -6px;
      right: 4px;
      font-size: 0.8rem;
      font-weight: 700;
      cursor: pointer;
  }
}